import React from 'react'
import "./Scrollup.css"

const Scrollup = () => {

  window.addEventListener("scroll", function (){
    const scrollup = document.querySelector(".scrollup");
    if(this.scrollY >= 80){
      scrollup.classList.add("show-scroll");
    }else{
      scrollup.classList.remove("show-scroll");
    }

})
    
  return (
    <a href="#home" className="scrollup">
        <i className="uil uil-arrow-up scrollup__icon"></i>
    </a>
  )
}

export default Scrollup